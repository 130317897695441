/* Container Styles */
.how-it-works-container {
  text-align: center;
  padding: 40px 15px;
  background-color: #fff;
}

/* Heading Styles */
.how-it-works-heading {
  font-size: 26px;
  font-weight: bold;
  color: #1d3557;
  margin-bottom: 25px;
}

/* Responsive Grid */
.how-it-works-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Card Styles */
.how-it-works-card {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.how-it-works-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Step Number */
.step-number {
  position: absolute;
  top: 12px;
  left: 12px;
  font-size: 22px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.15);
}

/* Icon Styling */
.icon {
  font-size: 40px;
  margin-bottom: 10px;
}

/* Title Styling */
.how-it-works-card h3 {
  color: #1d3557;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  position: relative;
}

.how-it-works-card h3::after {
  content: "";
  width: 40px;
  height: 3px;
  background: #e63946;
  display: block;
  margin: 5px auto 0;
}

/* Description Styling */
.how-it-works-card p {
  font-size: 14px;
  color: #333;
  line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 768px) {
  .how-it-works-heading {
    font-size: 22px;
  }

  .how-it-works-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .how-it-works-card {
    padding: 18px;
  }

  .icon {
    font-size: 36px;
  }

  .how-it-works-card h3 {
    font-size: 16px;
  }

  .how-it-works-card p {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .how-it-works-heading {
    font-size: 20px;
  }

  .how-it-works-grid {
    grid-template-columns: 1fr;
  }

  .how-it-works-card {
    padding: 15px;
  }

  .step-number {
    font-size: 18px;
    top: 10px;
    left: 10px;
  }

  .icon {
    font-size: 32px;
  }

  .how-it-works-card h3 {
    font-size: 16px;
  }

  .how-it-works-card p {
    font-size: 12px;
  }
}
