.Hm-pg2-Container {
  height: auto;
  min-height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5%;
  background-color: #f7f7f7;
  padding: 2rem;
  flex-wrap: wrap;
}

/* Image Styling */
.Hm-pg2-img1 {
  max-width: 40%;
}

.Hm-pg2-img1 img {
  width: 100%;
  height: auto;
}

/* Text Container */
.Hm-pg2-txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  color: rgb(0, 0, 0);
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
  max-width: 35%;
}

/* Heading */
.Hm-pg2-txt-head {
  font-size: 28px;
  font-weight: 700;
  padding-bottom: 1rem;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .Hm-pg2-Container {
    flex-direction: column;
    text-align: center;
  }

  .Hm-pg2-img1 {
    max-width: 60%;
  }

  .Hm-pg2-txt {
    max-width: 80%;
    font-size: 16px;
    line-height: 22px;
  }

  .Hm-pg2-txt-head {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .Hm-pg2-Container {
    padding: 1.5rem;
  }

  .Hm-pg2-img1 {
    max-width: 80%;
  }

  .Hm-pg2-txt {
    max-width: 90%;
    font-size: 14px;
    line-height: 20px;
  }

  .Hm-pg2-txt-head {
    font-size: 22px;
  }
}

@media (max-width: 480px) {
  .Hm-pg2-Container {
    padding: 1rem;
  }

  .Hm-pg2-img1 {
    max-width: 100%;
  }

  .Hm-pg2-txt {
    max-width: 100%;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .Hm-pg2-txt-head {
    font-size: 20px;
  }
}
