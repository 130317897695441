.pg6-ContainerHm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  background-color: #f7f7f7;
  padding: 40px 10px;
}

.pg6-txtHm {
  width: 90%;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
}

.pg6-txt-headHm {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.pg6-divsHm {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

.pg6-div1Hm,
.pg6-div2Hm,
.pg6-div3Hm,
.pg6-div4Hm {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 15px;
  height: auto;
  width: 280px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.pg6-div-hHm {
  color: #ff3e54;
  font-size: 18px;
  font-weight: 700;
}

.pg6-div-pHm {
  font-size: 14px;
  line-height: 1.4;
  width: 90%;
  font-weight: 500;
}

/* RESPONSIVE DESIGN */

/* Tablet View */
@media (max-width: 1024px) {
  .pg6-divsHm {
    flex-wrap: wrap;
    gap: 15px;
  }

  .pg6-div1Hm,
  .pg6-div2Hm,
  .pg6-div3Hm,
  .pg6-div4Hm {
    width: 220px;
    padding: 15px;
  }

  .pg6-txt-headHm {
    font-size: 24px;
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .pg6-txtHm {
    font-size: 16px;
  }

  .pg6-txt-headHm {
    font-size: 22px;
  }

  .pg6-divsHm {
    flex-direction: column;
    align-items: center;
  }

  .pg6-div1Hm,
  .pg6-div2Hm,
  .pg6-div3Hm,
  .pg6-div4Hm {
    width: 90%;
    padding: 20px;
  }

  .pg6-div-hHm {
    font-size: 16px;
  }

  .pg6-div-pHm {
    font-size: 14px;
  }
}
