.Hm-pg1-Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem;
}

/* Text Container */
.Hm-pg1-txtcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 50%;
}

/* Header Text */
.Hm-Pg1-txt2 {
  padding-top: 1rem;
  font-size: 36px;
  line-height: 50px;
  font-weight: 700;
  color: #ff3e54;
  width: 95%;
}

/* Download Button */
.Hm-Download-Now {
  height: 45px;
  width: 130px;
  display: flex;
  background-color: #ff3e54;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  justify-self: center;
  border-radius: 5px;
  font-family: "Raleway", sans-serif;
  cursor: pointer;
}

.Hm-Pg1-buts {
  display: flex;
  padding-top: 1rem;
  flex-direction: row;
  justify-self: center;

}

/* Image Styling */
.Hm-Pg1-img {
  padding-top: 3%;
  max-width: 40%;
}

.Hm-Pg1-img img {
  width: 100%;
  height: auto;
}

.Hm-Pg1-img2 {
  align-self: flex-start;
  padding-left: 5rem;
}

.Hm-Pg1-img2 img {
  width: 60px;
  height: auto;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .Hm-pg1-Container {
    flex-direction: column;
    text-align: center;
  }
  
  .Hm-pg1-txtcon {
    max-width: 80%;
  }

  .Hm-Pg1-txt2 {
    font-size: 28px;
    line-height: 42px;
    width: 90%;
  }

  .Hm-Pg1-img {
    max-width: 60%;
  }
}

@media (max-width: 768px) {
  .Hm-pg1-Container {
    padding: 1rem;
  }

  .Hm-pg1-txtcon {
    max-width: 100%;
  }

  .Hm-Pg1-txt2 {
    font-size: 24px;
    line-height: 36px;
  }

  .Hm-Download-Now {
    width: 100%;
    height: 50px;
    font-size: 16px;
  }

  .Hm-Pg1-img {
    max-width: 80%;
  }
}

@media (max-width: 480px) {
  .Hm-pg1-Container {
    padding: 1rem;
  }

  .Hm-Pg1-txt2 {
    font-size: 20px;
    line-height: 30px;
    width: 100%;
  }

  .Hm-Download-Now {
    width: 100%;
    height: 50px;
    font-size: 14px;
  }

  .Hm-Pg1-img {
    max-width: 90%;
  }

  .Hm-Pg1-img2 {
    padding-left: 0;
  }

  .Hm-Pg1-img2 img {
    width: 40px;
  }
}
