.join-container {
  text-align: center;
  padding: 40px 10px;
  background-color: #fff;
}

.join-heading {
  font-size: 24px;
  font-weight: bold;
  color: #1d3557;
  margin-bottom: 20px;
}

/* Responsive Grid */
.join-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Card Styling */
.join-card {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.join-card:hover {
  transform: translateY(-5px);
}

.join-card h3 {
  color: #1d3557;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  position: relative;
}

.join-card h3::after {
  content: "";
  width: 40px;
  height: 3px;
  background: #e63946;
  display: block;
  margin: 5px auto 0;
}

.join-card p {
  font-size: 14px;
  color: #333;
  line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 768px) {
  .join-heading {
    font-size: 22px;
  }

  .join-card {
    padding: 15px;
  }

  .join-card h3 {
    font-size: 16px;
  }

  .join-card p {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .join-heading {
    font-size: 20px;
  }

  .join-grid {
    grid-template-columns: 1fr;
  }

  .join-card {
    padding: 15px;
  }

  .join-card h3 {
    font-size: 16px;
  }

  .join-card p {
    font-size: 12px;
  }
}
