/* Section Styling */
.what-we-doHm {
  padding: 60px 20px;
  background-color: #fff;
}

/* Main Container */
.containerHm {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
}

/* Image Styling */
.image-containerHm {
  flex: 1;
  display: flex;
  justify-content: center;
}

.image-containerHm img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}

/* Content Styling */
.contentHm {
  flex: 1;
  text-align: left;
}

/* Subtitle */
.subtitleHm {
  font-size: 18px;
  font-weight: bold;
  color: #e63946;
  display: block;
  margin-bottom: 10px;
}

/* Title */
.titleHm {
  font-size: 32px;
  font-weight: bold;
  color: #1d3557;
  margin-bottom: 15px;
}

/* Description */
.descriptionHm {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Info Cards */
.info-cardsHm {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

/* Individual Card */
.cardHm {
  background: #fdecec;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  flex: 1;
  min-width: 200px;
}

.cardHm h3 {
  color: #e63946;
  font-size: 18px;
  margin-bottom: 5px;
}

.cardHm p {
  font-size: 14px;
  color: #555;
}

/* Button */
.view-moreHm {
  background-color: #e63946;
  color: #fff;
  padding: 12px 25px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease-in-out;
}

.view-moreHm:hover {
  background-color: #d62828;
}

/* ✅ RESPONSIVENESS */

/* Tablet View (Stacking) */
@media (max-width: 1024px) {
  .containerHm {
    flex-direction: column;
    text-align: center;
  }

  .image-containerHm img {
    max-width: 400px;
  }

  .contentHm {
    text-align: center;
  }

  .info-cardsHm {
    flex-direction: column;
    align-items: center;
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .titleHm {
    font-size: 28px;
  }

  .descriptionHm {
    font-size: 15px;
  }

  .cardHm {
    width: 100%;
    max-width: 300px;
  }

  .view-moreHm {
    width: 100%;
  }
}

/* Small Mobile View */
@media (max-width: 480px) {
  .what-we-doHm {
    padding: 40px 15px;
  }

  .titleHm {
    font-size: 24px;
  }

  .subtitleHm {
    font-size: 16px;
  }

  .cardHm {
    padding: 12px;
  }

  .view-moreHm {
    font-size: 14px;
  }
}
