/* Feature Section Wrapper */
.feature-wrapperHm {
  text-align: center;
  padding: 60px 20px;
  background-color: #f9f9f9;
}

/* Heading */
.section-headingHm {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px;
  color: #333;
}

/* Responsive Grid Layout */
.feature-gridHm {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 25px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Feature Box */
.feature-boxHm {
  background: #ffffff;
  padding: 25px;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.feature-boxHm:hover {
  transform: translateY(-8px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

/* Icon */
.feature-iconHm {
  font-size: 42px;
  margin-bottom: 12px;
}

/* Heading */
.feature-headingHm {
  font-size: 20px;
  font-weight: 700;
  color: #222;
  margin-bottom: 12px;
}

/* Feature Image */
.feature-imgHm {
  width: 100%;
  max-width: 160px;
  margin: 10px 0;
}

/* Description */
.feature-textHm {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  width: 90%;
}

/* ✅ RESPONSIVENESS */

/* Tablet View */
@media (max-width: 1024px) {
  .feature-gridHm {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .section-headingHm {
    font-size: 28px;
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .feature-gridHm {
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .section-headingHm {
    font-size: 26px;
  }
  .feature-boxHm {
    padding: 20px;
  }
}

/* Small Mobile View */
@media (max-width: 480px) {
  .feature-wrapperHm {
    padding: 40px 15px;
  }
  .section-headingHm {
    font-size: 24px;
  }
  .feature-iconHm {
    font-size: 36px;
  }
  .feature-headingHm {
    font-size: 18px;
  }
  .feature-textHm {
    font-size: 14px;
  }
}
