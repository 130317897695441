.industry-containers {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  background-color: #f7f7f7;
}

/* Header Section */
.industry-text {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0e1f51;
  position: relative;
  text-align: center;
  padding: 20px;
}

.industry-text div:nth-child(2) {
  color: white;
  font-size: 24px;
  line-height: 20px;
  font-weight: 500;
  margin-top: 5px;
}

.industry-head {
  color: #ff3e54;
  font-size: 48px;
  line-height: 58px;
  font-weight: 700;
}

.industry-container {
  padding: 60px 20px;
  background-color: #fff;
}

/* Wrapper */
.industry-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
  flex-wrap: wrap; /* Makes it responsive */
  padding: 20px;
}

/* Image Box */
.industry-image img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}

/* Text Content */
.industry-content {
  flex: 1;
  text-align: left;
  padding: 20px;
}

.industry-highlight {
  font-size: 18px;
  font-weight: bold;
  color: #e63946;
  display: block;
  margin-bottom: 10px;
}

.industry-heading {
  font-size: 32px;
  font-weight: bold;
  color: #1d3557;
  margin-bottom: 15px;
}

.industry-description {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Feature Cards */
.industry-features {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Ensures responsiveness */
}

.industry-feature-item {
  background: #fdecec;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  flex: 1;
  min-width: 200px; /* Ensures smaller screens don’t squeeze too much */
}

.industry-feature-item h3 {
  color: #e63946;
  font-size: 16px;
  margin-bottom: 5px;
}

.industry-feature-item p {
  font-size: 14px;
  color: #555;
}

/* Responsive Button */
.industry-button {
  background-color: #e63946;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
  text-align: center;
  display: block;
  margin: 0 auto;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 1024px) {
  .industry-wrapper {
    flex-direction: column;
    text-align: center;
  }

  .industry-content {
    text-align: center;
    padding: 10px;
  }

  .industry-features {
    flex-direction: column;
    align-items: center;
  }

  .industry-feature-item {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .industry-head {
    font-size: 35px;
  }

  .industry-text div:nth-child(2) {
    font-size: 16px;
  }

  .industry-heading {
    font-size: 28px;
  }

  .industry-description {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .industry-head {
    font-size: 28px;
  }

  .industry-text div:nth-child(2) {
    font-size: 14px;
  }

  .industry-heading {
    font-size: 24px;
  }

  .industry-description {
    font-size: 13px;
  }

  .industry-button {
    padding: 8px 15px;
    font-size: 14px;
  }
}
