/* Background and Container */
.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    min-width: 100dvw;
    background-color: #0a1c44;
    padding: 20px;
    flex-wrap: wrap;
    gap: 3rem;
  }
  
  /* Contact Box */
  .contact-box {
    display: flex;
    flex-wrap: wrap;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    max-width: 800px;
   width: 100%;
  }
  
  /* Form Section */
  .form-section {
    width: 95%;
    padding: 30px;
  }
  
  .subtitle {
    color: red;
    font-size: 16px;
    text-align: center;
  }
  .info-item img{
    height: 50px;
  }
  .title {
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .input-field {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .textarea {
    height: 80px;
  }
  
  .send-btn {
    background-color: red;
    color: white;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .send-btn:hover {
    background-color: darkred;
  }
  
  /* Info Section */
  .info-sections {
  background-color: white;
  padding: 4rem 4rem 2rem 2rem;
  border-radius: 10px;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .icon {
    color: red;
    font-size: 20px;
  }
  
  .info-item a {
    color: #333;
    text-decoration: none;
    font-weight: 500;
  }
  
  /* Social Media */
  .social-section {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
  }
  
  .social-section h3 {
    margin-bottom: 10px;
    color: #333;
    
  }
  
  .social-icons {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  
  .social-btn {
    background-color: red;
    color: white;
    padding: 10px;
    border-radius: 50%;
    font-size: 18px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s;
  }
  
  .social-btn:hover {
    background-color: darkred;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .contact-box {
      flex-direction: column;
      align-items: center;
      max-width: 100%;
    }
  
    .form-section {
      width: 100%;
      padding: 20px;
    }
  
    .info-section {
      width: 100%;
      padding: 20px;
    }
  
    .social-icons {
      justify-content: center;
    }
  }
  
  @media (max-width: 768px) {
    .contact-container {
      padding: 10px;
    }
  
    .contact-box {
      max-width: 100%;
    }
  
    .form-section {
      padding: 15px;
    }
  
    .title {
      font-size: 24px;
    }
  
    .send-btn {
      font-size: 14px;
    }
  
    .info-section {
      padding: 15px;
    }
  
    .social-icons {
      gap: 8px;
    }
  }
  
  @media (max-width: 480px) {
    .contact-container {
      padding: 5px;
    }
  
    .contact-box {
      max-width: 100%;
      flex-direction: column;
    }
  
    .form-section {
      padding: 10px;
    }
  
    .title {
      font-size: 20px;
    }
  
    .send-btn {
      font-size: 14px;
    }
  
    .info-section {
      padding: 10px;
    }
  
    .social-icons {
      gap: 5px;
    }
  }
  