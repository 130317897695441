.benefits-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #ffffff;
}

.benefits-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #1d1d1d;
}

.benefits-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.benefit-card {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.benefit-card:hover {
  transform: translateY(-5px);
}

.benefit-image {
  width: 100%;
  height: auto;
  border-radius: 6px;
  margin-bottom: 15px;
}

.benefit-heading {
  font-size: 18px;
  font-weight: 700;
  color: #e63946;
  margin-bottom: 10px;
}

.benefit-description {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 768px) {
  .benefits-container {
    flex-direction: column;
    align-items: center;
  }

  .benefit-card {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .benefits-title {
    font-size: 24px;
  }

  .benefit-heading {
    font-size: 16px;
  }

  .benefit-description {
    font-size: 14px;
  }
}
