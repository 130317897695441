/* Section Styling */
.what-we-do {
  padding: 60px 20px;
  background-color: #fff;
}

/* Main Container */
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
}

/* Image Styling */
.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.image-container img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}

/* Content Styling */
.content {
  flex: 1;
  text-align: left;
}

/* Subtitle */
.subtitle {
  font-size: 18px;
  font-weight: bold;
  color: #e63946;
  display: block;
  margin-bottom: 10px;
}

/* Title */
.title {
  font-size: 32px;
  font-weight: bold;
  color: #1d3557;
  margin-bottom: 15px;
}

/* Description */
.description {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Info Cards */
.info-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

/* Individual Card */
.card {
  background: #fdecec;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  flex: 1;
  min-width: 200px;
}

.card h3 {
  color: #e63946;
  font-size: 18px;
  margin-bottom: 5px;
}

.card p {
  font-size: 14px;
  color: #555;
}

/* Button */
.view-more {
  background-color: #e63946;
  color: #fff;
  padding: 12px 25px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease-in-out;
}

.view-more:hover {
  background-color: #d62828;
}

/* ✅ RESPONSIVENESS */

/* Tablet View (Stacking) */
@media (max-width: 1024px) {
  .container {
    flex-direction: column;
    text-align: center;
  }

  .image-container img {
    max-width: 400px;
  }

  .content {
    text-align: center;
  }

  .info-cards {
    flex-direction: column;
    align-items: center;
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .title {
    font-size: 28px;
  }

  .description {
    font-size: 15px;
  }

  .card {
    width: 100%;
    max-width: 300px;
  }

  .view-more {
    width: 100%;
  }
}

/* Small Mobile View */
@media (max-width: 480px) {
  .what-we-do {
    padding: 40px 15px;
  }

  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: 16px;
  }

  .card {
    padding: 12px;
  }

  .view-more {
    font-size: 14px;
  }
}
