/* Container Styles */
.highlights-container {
  text-align: center;
  padding: 40px 15px;
  background-color: #f9f9f9;
}

/* Heading Styles */
.highlights-heading {
  font-size: 26px;
  font-weight: bold;
  color: #1d3557;
  margin-bottom: 25px;
}

/* Responsive Grid */
.highlights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Card Styles */
.highlight-card {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.highlight-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.highlight-card h3 {
  color: #1d3557;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  position: relative;
}

.highlight-card h3::after {
  content: "";
  width: 40px;
  height: 3px;
  background: #e63946;
  display: block;
  margin-top: 5px;
}

.highlight-card p {
  font-size: 14px;
  color: #333;
  line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 768px) {
  .highlights-heading {
    font-size: 22px;
  }

  .highlight-card {
    padding: 18px;
  }

  .highlight-card h3 {
    font-size: 16px;
  }

  .highlight-card p {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .highlights-heading {
    font-size: 20px;
  }

  .highlights-grid {
    grid-template-columns: 1fr;
  }

  .highlight-card {
    padding: 15px;
  }

  .highlight-card h3 {
    font-size: 16px;
  }

  .highlight-card p {
    font-size: 12px;
  }
}
