@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Adjust these styles according to your needs */
.custom-arrow {
  color: #006BD3; /* Change to your desired color */
  font-size: 24px; /* Adjust size */
  width: 40px; /* Adjust width */
  height: 40px; /* Adjust height */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; /* Ensure arrows are on top */
}

.custom-prev-arrow {
  left: 10px; /* Adjust positioning */
}

.custom-next-arrow {
  right: 10px; /* Adjust positioning */
}

/* Optional: Add hover effect */
.custom-arrow:hover {
  color: #004a8c; /* Change hover color */
}

