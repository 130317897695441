.app-features {
  text-align: center;
  padding: 50px 20px;
  background-color: #ffffff;
}

.title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.feature-card {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.icon {
  font-size: 40px;
  margin-bottom: 10px;
}

.feature-title {
  font-size: 18px;
  font-weight: 800;
  text-align: center;
  line-height: 20px;
  color: #333;
}

.feature-description {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #555;
  width: 80%;
  align-items: center;
}
